html {
  /*
   * For more information on dynamic font scaling, visit the documentation:
   * https://ionicframework.com/docs/layout/dynamic-font-scaling
   */
  //* Set default font
  --ion-dynamic-font: 'Montserrat' !important;
  --ion-font-family: 'Montserrat' !important;
  --ion-backdrop-color: #012A36 !important;
}
